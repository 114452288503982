/* Importando estilos base do Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ajuste de fontes */
body {
  font-family: 'Roboto', sans-serif; /* Fonte limpa e moderna */
  font-size: 16px;
  background-color: #f9f9f9; /* Fundo cinza claro */
  color: #333; /* Texto escuro */
}

/* Ajuste para telas menores */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

/* Remover scrollbar */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE e Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari e Opera */
}

/* Estilo para botões */
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* Animação ao passar o mouse */
button:hover {
  transform: scale(1.05); /* Aumenta ligeiramente o botão */
}

/* Estilo para a Navbar */
.navbar {
  background-color: #ffffff; /* Fundo branco */
  border-bottom: 1px solid #e0e0e0; /* Linha inferior sutil */
  padding: 1rem;
  display: flex;
  gap: 1rem;
  overflow-x: auto; /* Permite rolagem horizontal */
}

/* Estilo dos botões da Navbar */
.navbar button {
  background-color: #ffffff;
  color: #333333;
  padding: 0.5rem 1rem;
  border-radius: 9999px; /* Bordas arredondadas */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra leve */
}
.navbar button:hover {
  background-color: #f2f2f2; /* Fundo cinza claro ao passar o mouse */
  color: #111111;
}

/* Categoria */
.category-section {
  margin: 2rem 0;
  padding: 0 1rem;
  padding-top: 100px;
}
.category-section h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Grid de produtos */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

/* Estilo do card de produtos */
.product-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Sombra leve */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.product-card:hover {
  transform: scale(1.05); /* Aumenta ligeiramente o card */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Sombra mais pronunciada */
}
.product-card img {
  border-radius: 8px 8px 0 0; /* Arredonda a parte superior */
  object-fit: cover;
  width: 100%;
  height: 150px;
}
.product-card h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #333333;
  margin: 0.5rem 0;
}
.product-card p {
  font-size: 0.875rem;
  color: #666666;
}
.product-card .price {
  font-size: 1.25rem;
  font-weight: bold;
  color: #28a745; /* Verde para preço */
}
.product-card button {
  background-color: #dc3545; /* Vermelho para botão */
  color: #ffffff;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
}
.product-card button:hover {
  background-color: #c82333;
}

/* Footer fixado */
footer {
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0; /* Linha superior sutil */
  padding: 1rem;
  display: flex;
  justify-content: space-around;
}
footer button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
}
footer button span {
  font-size: 1.5rem;
}
footer button:hover {
  color: #111111;
}

/* Header */
header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para separação */
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
header h1 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
}
header p {
  font-size: 0.875rem;
  color: #666666;
}
header input {
  border: 1px solid #cccccc;
  border-radius: 9999px; /* Totalmente arredondado */
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
header input:focus {
  outline: none;
  border-color: #28a745; /* Verde no foco */
  box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.2);
}

/* Ajuste do menu para dispositivos móveis */
.navbar {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
}
.navbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari e Opera */
}

body {
  padding-bottom: 60px; /* Espaço igual à altura da barra inferior */
}

.footer-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  z-index: 1003; /* Certifica que ela fica acima de tudo */
}



.scrollbar-hide {
  -ms-overflow-style: none; /* Para IE e Edge */
  scrollbar-width: none; /* Para Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Para Chrome, Safari e Opera */
}

/* Botão do menu ativo */
button {
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

/* Ajuste no menu para evitar sobreposição */
.sticky {
  position: sticky;
  top: 0; /* Posição fixa */
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;
}

.sticky.scrolled {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Remove scrollbars para o carrossel */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

/* Estilo dos cards */
.card {
  min-width: 200px; /* Largura mínima para os produtos no carrossel */
}

/* Estilo do modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050; /* Garantir que o modal esteja acima */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  z-index: 1001; /* Conteúdo acima do overlay */
}



